<template>
  <div id="header-project" class="organism-menu-bar-offer">
    <div class="row align-items-center">
      <div class="col col-xs-4 col-4">
        <div class="block-element">
          <router-link
            tag="div"
            :to="{ name: !getSkipBuildingInit ? 'Home' : 'Space' }"
            class="inline-elements"
          >
            <AtomLogo />
          </router-link>
        </div>
      </div>
      <div class="col col-xs-8 col-8">
        <div class="block-element text-right">
          <div class="inline-elements">
            <div class="menu">
              <slot></slot>
            </div>
          </div>
          <div class="inline-elements hidden-lg">
            <MoleculeMenuTrigger />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AtomLogo from "../atoms/common/AtomLogo";
import MoleculeMenuTrigger from "../molecules/MoleculeMenuTrigger";
export default {
  name: "OrganismMenuBarOffer",
  components: { MoleculeMenuTrigger, AtomLogo },
  computed: {
    getSkipBuildingInit() {
      return this.$store.getters.getSkipBuildingInit;
    },
  },
};
</script>

<style lang="scss">
.organism-menu-bar-offer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 0.625rem;
  z-index: 9;
  background: $white;
  @media only screen and (max-width: 767px) {
    padding: 10px;
  }
  .inline-elements {
    display: inline-block;
    vertical-align: middle;
  }
  .atom-logo {
    margin: 0 1.563rem 0 0.625rem;
    @media only screen and (max-width: 767px) {
      margin: 0 0 0 8px;
    }
    img {
      display: block;
      max-width: 4rem;
      max-height: 4rem;
      @media only screen and (max-width: 767px) {
        max-width: 52px;
        max-height: 52px;
      }
    }
  }
  .link-atom {
    background: rgba(58, 189, 89, 0.2);
    border-color: transparent;
    padding: 0.875rem 1.25rem;
    margin-right: 0.625rem;
    @media only screen and (max-width: 767px) {
      padding: 15px 10px;
      margin-right: 8px;
    }
    span {
      color: #3abd59;
      font-weight: 500;
      font-size: 0.875rem;
      line-height: 1.063rem;
      @media only screen and (max-width: 767px) {
        font-size: 12px;
        line-height: 15px;
      }
    }
    &:hover {
      background: #3abd59;
      border-color: transparent;
      @media only screen and (max-width: 1200px) {
        background: rgba(58, 189, 89, 0.2);
      }
      span {
        color: $white;
        @media only screen and (max-width: 1200px) {
          color: #3abd59;
        }
      }
    }
    &.active {
      background: #3abd59 !important;
      border-color: transparent !important;
      span {
        color: $white !important;
      }
    }
  }
  .molecule-menu-trigger {
    width: 3.25rem;
    height: 3.25rem;
    border-color: transparent;
    @media only screen and (max-width: 767px) {
      width: 52px;
      height: 52px;
    }
    .icon {
      font-size: 1.25rem !important;
      color: $black !important;
      @media only screen and (max-width: 767px) {
        font-size: 20px !important;
      }
    }
  }
  .menu {
    margin-right: 10px;
    .sidebar-link {
      font-weight: 500;
      font-size: 0.875rem;
      line-height: 1.063rem;
      letter-spacing: 0.04em;
      text-transform: uppercase;
      color: #656565;
      text-decoration: none;
      margin: 0 1rem;
      padding-bottom: 0.25rem;
      border-bottom: 0.25rem solid transparent;
      transition: all 0.2s ease-in-out 0s;
      &:hover,
      &.active {
        color: $second-color;
        border-color: $second-color;
      }
    }
  }
}
</style>
